@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./fonts/Avenir_35_Light.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Avenir';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.margin-top-md {
  margin-top: 16px;
}

.margin-top-lg {
  margin-top: 32px;
}

.margin-bottom-md {
  margin-bottom: 16px;
}

.margin-left-md {
  margin-left: 16px;
}

.margin-left-sm {
  margin-left: 8px;
}

.margin-right-md {
  margin-right: 16px;
}

.expand {
  width: 100%;
}
