.button-container {
  height: inherit;
  width: inherit;
}

.button-content {
  text-align: center;
}

.button-container .button-body {
	border-radius:var(--corvid-border-radius,var(--rd,0));
	transition:var(--trans1,border-color .4s ease 0s,background-color .4s ease 0s);
	box-shadow:var(--shd,0 1px 4px rgba(0,0,0,.6))
}

.button-container .button-body:link,
.button-container .button-body:visited {
	border-color:transparent
}

.button-container .button-content {
	transition:var(--trans2,color .4s ease 0s);
	color:var(--corvid-color,rgb(var(--txt,var(--fnt))));
	white-space:nowrap;
	margin:0
}

.button-container .button-body {
	background-color:var(--corvid-background-color,rgba(var(--bg,var(--color_17)),var(--alpha-bg,1)));
	border:solid var(--corvid-border-color,rgba(var(--brd,var(--color_15)),var(--alpha-brd,1))) var(--corvid-border-width,var(--brw,0));
	cursor:pointer !important;
  height: inherit;
  display: flex;
  align-items: center;
}

.button-container[aria-disabled=false]:active .button-body {
	background-color:rgba(var(--bgh,var(--color_18)),var(--alpha-bgh,1));
	border-color:rgba(var(--brdh,var(--color_15)),var(--alpha-brdh,1))
}

.button-container[aria-disabled=false]:active .button-content {
	color:rgb(var(--txth,var(--color_15)))
}

.button-container[aria-disabled=false]:hover .button-body {
	background-color:rgba(var(--bgh,var(--color_18)),var(--alpha-bgh,1));
	border-color:rgba(var(--brdh,var(--color_15)),var(--alpha-brdh,1))
}

.button-container[aria-disabled=false]:hover .button-content {
	color:rgb(var(--txth,var(--color_15)))
}

.button-container[aria-disabled=true] .button-body {
	background-color:rgba(var(--bgd,204,204,204),var(--alpha-bgd,1));
	border-color:rgba(var(--brdd,204,204,204),var(--alpha-brdd,1));
	border-width:var(--corvid-border-width,var(--brw,0));
	border-style:solid
}

.button-container[aria-disabled=true] .button-content {
	color:rgb(var(--txtd,255,255,255))
}

.button-content strong {
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

#button-variables {
    --rd: 20px;
    --trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    --shd: none;
    --fnt: Avenir ,sans-serif;
    --trans2: color 0.4s ease 0s;
    --txt: 50,50,50;
    --brw: 2px;
    --bg: 255,255,255;
    --brd: 50,50,50;
    --bgh: 50,50,50;
    --brdh: 50,50,50;
    --txth: 255,255,255;
    --bgd: 204,204,204;
    --alpha-bgd: 1;
    --brdd: 204,204,204;
    --alpha-brdd: 1;
    --txtd: 255,255,255;
    --alpha-txtd: 1;
    --boxShadowToggleOn-shd: none;
    --alpha-txt: 1;
    --alpha-brdh: 0;
    --alpha-brd: 1;
    --alpha-txth: 1;
    --alpha-bg: 0;
    --alpha-bgh: 1;
    --shc-mutated-brightness: 128,128,128;
    --label-align: center;
    --label-text-align: center;
    --color_15: 255,255,255;
    --color_17: 100,86,61;
    --color_18: 149,129,91;
}
