body {
  font-family: Avenir, serif;
}

.form-container {
    margin: 32px;
}

.instruments {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
}

.search-button {
    width: 128px;
    height: 40px;
}

.oms-control-button {
    border: none;
    height: 40px;
}

.remove-student-button {
    float: right;
}

.submit-button {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: transparent;
    color: inherit;
    border-style: none;
    font-weight: bold;
}


.oms-radio-checkbox-select.form-check {
    border: 1px solid #212529;
    padding-left: 0;
}

.oms-radio-checkbox-select.form-check:hover {
    background-color:rgba(32, 32, 32, 0.15);
}


.oms-radio-checkbox-select.form-check {
    border: 1px solid #212529;
    padding-left: 0;
}

.oms-radio-checkbox-select .form-check-label {
    width: 100%;
    display: block;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
}

.oms-radio-checkbox-select .form-check-input {
    border: 1px solid #212529;
    margin-left: 8px !important;
    margin-right: 8px;
    margin-top: 20px;
}

.oms-radio-checkbox-select .form-check-input:checked {
    background-color: #212529;
    border-color: #212529;
}

.oms-radio-checkbox-select .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(77, 77, 77, 0.25)
}

.oms-radio-checkbox .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(77, 77, 77, 0.25)
}

.oms-radio-checkbox .form-check-input {
    border: 1px solid #212529;
}

.oms-radio-checkbox .form-check-input:checked {
    background-color: #212529;
    border-color: #212529;
}

.selected-appointments {
    height: 82px;
}

.card-title-pagination {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

}

.alert-message {
    margin: 16px;
}

.submit-another {
    text-decoration: underline;
    cursor: pointer;
}